<template>
  <div class="">
    <div id="rmvovf" class="columns">
      <div class="column is-7-desktop is-full-tablet is-full-mobile jsmb-listing-research" :style="showMap ? 'display:none' : ''">
        <!-- <div class="annonce-search-input">
          <div class="control">
            <input class="input" type="text" placeholder="Text input">
          </div>
          <div class="control">
            <button class="button is-link">Submit</button>
          </div>
        </div> -->

        <div :class="$mq | mq({ 
          desktop: 'annonce-research-container',
          tablet: 'annonce-research-container',
          mobile: 'annonce-research-container-mobile',
          
           })" class="">
          <div class="buttons">
            <b-dropdown :hoverable="!displayOnMobile" ref="dropdown">
              <button  class="button myPrimary" slot="trigger">Type de location</button>
              <b-dropdown-item custom>
                <h4 class="has-text-grey espace-annonce"><span class="has-text-weight-bold is-uppercase">Votre location</span></h4>
                <div class="field" v-for="(locationType, index) in locationTypes" :key="'location-type-'+index">
                  <b-checkbox v-model="locationTypesCheck" :native-value="locationType.id">
                    <strong>{{locationType.name}}</strong><br>
                    <small>{{locationType.description}}</small>
                  </b-checkbox>
                </div>
                <!-- <div class="field">
                  <b-checkbox v-model="checkboxGroupLocation" native-value="Tous">
                    <strong>Toutes les annonces</strong>
                  </b-checkbox>
                </div> -->
              </b-dropdown-item>
              <div class="has-text-right">
                <!-- <button class="button i is-small is-primary" type="button" v-if="displayOnMobile" @click="$children[0].isActive = false">Fermer</button> -->
                <a class="refresh-search-button" @click="locationTypesCheckbox">Appliquer</a>
              </div>
            </b-dropdown>

            <b-dropdown :hoverable="!displayOnMobile" class="annonce-surface" position="is-bottom-center">
              <button class="button myPrimary" slot="trigger">Surface</button>

              <b-dropdown-item custom class="area-research">
                <Area @update-area="updateArea" :hideForModal="true" :boatData="boatData" :key="(boatData.length || boatData.height || boatData.width)"/>
              </b-dropdown-item>
              <!-- <div class="has-text-centered">
                <button class="button is-small is-primary" type="button" v-if="displayOnMobile" @click="$children[1].isActive = false">Fermer</button>
              </div> -->
            </b-dropdown>

            <b-dropdown :hoverable="!displayOnMobile">
              <button class="button myPrimary" slot="trigger">Type d'espace</button>

              <b-dropdown-item custom>
                <small class="has-text-grey espace-annonce">( Choix multiples )</small>

                <div class="field" v-for="(spaceType, index) in spaceTypes" :key="'space-type-'+index">
                  <b-checkbox v-model="spaceTypesCheck" :native-value="spaceType.id">
                    <small>{{spaceType.name}}</small>
                  </b-checkbox>
                </div>
              </b-dropdown-item>
              <div class="has-text-right">
                <!-- <button class="button is-small is-primary" type="button" v-if="displayOnMobile" @click="$children[2].isActive = false">Fermer</button> -->
                <a class="refresh-search-button" @click="spaceTypesCheckbox">Appliquer</a>
              </div>
            </b-dropdown>

            <b-dropdown :hoverable="!displayOnMobile" position="is-bottom-left" class="annonce-option-date">
              <button class="button myPrimary" slot="trigger">Date</button>
              <b-dropdown-item custom>
                <Time @update-time="updateTime" :beginDate="beginDate" :endDate="endDate" />
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown :hoverable="!displayOnMobile" position="is-bottom-left" class="annonce-option-dropdown">
              <button class="button is-danger myPrimary" slot="trigger">Options</button>

              <b-dropdown-item custom>
                <div class="annonce-options">
                  <h4 class="has-text-grey espace-annonce"><span class="has-text-weight-bold is-uppercase">sécurité</span> ( Choix multiples )</h4>
                  <div class="field" v-for="(security, index) in securities" :key="'security-'+index">
                    <b-checkbox v-model="securitiesCheck" :native-value="security.id">
                      <small>{{security.name}}</small>
                    </b-checkbox>
                  </div>

                  <br/>

                  <h4 class="has-text-grey espace-annonce"><span class="has-text-weight-bold is-uppercase">options</span><br>(Choix multiples /  5€/mois suplémentaires par option)</h4>
                  <div class="field" v-for="(option, index) in options" :key="'option-'+index">
                    <b-checkbox v-model="optionsCheck" :native-value="option.id">
                      <small>{{option.name}}</small>
                    </b-checkbox>
                  </div>
                </div>
              </b-dropdown-item>

              <div class="has-text-right">
                <!-- <button class="button is-small is-primary" type="button" v-if="displayOnMobile" @click="$children[4].isActive = false">Fermer</button> -->
                <a class="refresh-search-button" @click="securitiesOptionsCheckbox">Appliquer</a>
              </div>
            </b-dropdown>

            <b-dropdown :hoverable="!displayOnMobile" position="is-bottom-left" class="annonce-option-dropdown">
              <button class="button myPrimary" slot="trigger">Type de Bateau-Stockeur</button>

              <b-dropdown-item custom>
                <h4 class="has-text-grey espace-annonce"><span class="has-text-weight-bold is-uppercase">Votre bateau-Stockeur</span></h4>
                <div class="field" v-for="(accountType, index) in accountTypes" :key="'account-type'+index">
                  <b-checkbox v-model="accountTypesCheck" :native-value="accountType.id">
                    <small>{{accountType.name}}</small>
                  </b-checkbox>
                </div>
                <!-- <div class="field">
                  <b-checkbox v-model="checkboxGroupTypeBS" native-value="Particulier et professionnel">
                    <small>Particulier et professionnel</small>
                  </b-checkbox>
                </div> -->
              </b-dropdown-item>
              <div class="has-text-centered">
              </div>
              <div class="has-text-right">
                <!-- <button class="button is-small is-primary" type="button" v-if="displayOnMobile" @click="$children[5].isActive = false">Fermer</button> -->
                <a class="refresh-search-button" @click="accountTypesCheckbox">Appliquer</a>
              </div>
            </b-dropdown>
            <div class="field is-hidden-desktop">
              <b-switch v-model="showMap">Afficher la carte</b-switch>
            </div>
          </div>
        </div>

        <div class="annonce-research-container">
          <h2 v-if="(this.city || this.geolocCity)">Découvrez la liste des places à sec disponibles sur <strong class="has-text-primary" v-if="this.city">{{ this.city.charAt(0).toUpperCase() + this.city.slice(1) }}</strong><strong class="has-text-primary" v-else-if="this.geolocCity">{{ this.geolocCity }}</strong> et alentours :</h2>
          <h2 v-if="noSpaces"><br />Il n'y a pas de Bateau Stockeur dans votre zone de recherche.</h2>
        </div>

        <div class="annonce-research-container" style="position:relative;min-height:50px;top:25px;" v-if="isLoadingSpaces">
          <b-loading :is-full-page="false" :active.sync="isLoadingSpaces"></b-loading>
        </div>

        <div class="columns is-mobile is-2 is-variable is-multiline research-container">
          <div class="column is-half-desktop is-half-tablet is-full-mobile annonce-main" v-for="(space, index) in spaces" :key="'space-'+index">
            <div class="annonce-container">
              <figure class="image">
                <router-link :to="{name: 'Detail', params: {id:space.space_id, city:space.city.toLowerCase()}}" tag="a" push>
									<img :src="space.picture">
								</router-link>
                <figcaption class="is-uppercase is-size-6" v-if="space.is_professional">Professionnel</figcaption>
              </figure>

              <div class="annonce-content">
                <div class="columns is-multiline is-0">
                  <div class="column is-12">
                    <p class="jsmb-favorite-type is-size-6 is-uppercase has-text-grey has-text-weight-bold">{{space.space_type}} / EMPLACEMENT {{space.location_type}}</p>
                    <router-link :to="{name: 'Detail', params: {id:space.space_id, city:space.city.toLowerCase()}}" tag="a" push>
                      <h4 class="jsmb-favorite-type-area is-size-5 has-text-primary has-text-weight-bold">
                        {{space.announcement_title}} - {{space.space_title}}
                      </h4>
                    </router-link>
                    <small class="jsmb-favorite-adress">{{space.city}} - {{space.zip_code}}</small>
                  </div>
                  <div class="column has-text-right">
                    <h4 class="jsmb-size has-text-weight-bold is-size-5">{{space.surface}} m²</h4>
                  </div>
                </div>

                <div class="columns is-0 is-multiline is-vcentered jsmb-favorite-prix">
                  <div class="column is-6-desktop">
                    <p v-if="isPro"><strong class="has-text-weight-bold">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(space.price_ht)}}</strong> HT / jour</p>
                    <p><strong class="has-text-weight-bold">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(space.price_ttc)}}</strong> TTC / jour</p>
                  </div>

                  <div class="column has-text-right annonce-social">
                    <div class="dropdown is-up jsmb-share" :class="{ 'is-active' : space.is_share }">
                      <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="changeDropDownState(space)">
                          <i class="fas fa-share-alt"></i>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <!-- Insérer lien de partage : base + url de l'article-->
                          <a :href="'https://www.facebook.com/sharer/sharer.php?u='+getUrlAnnouncement('Detail', {id:space.space_id, city:space.city})" target="_blank" class="button jsmb-inline">
                            <i class="fab fa-facebook"></i>
                          </a>
                          <a :href="'https://twitter.com/home?status='+getUrlAnnouncement('Detail', {id:space.space_id, city:space.city})" target="_blank" class="button jsmb-inline">
                            <i class="fab fa-twitter"></i>
                          </a>
                          <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source='+getUrlAnnouncement('Detail', {id:space.space_id, city:space.city})" target="_blank" class="button jsmb-inline">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <FavoriteButton :spaceId="space.space_id" :isFavorite="space.is_favorite" :key="$store.getters.userId" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="total > perPage" class="annonce-research-container">
          <b-pagination
            :total="total"
            :current.sync="current"
            :order="order"
            :size="size"
            :simple="true"
            :rounded="isRounded"
            :per-page="perPage"
            @change="announcementPage"
          ></b-pagination>
        </div>
      </div>
      <!-- MAP COLUMNS -->
      <div id="gmap" class="column is-5-desktop"  style="position:relative" :class="showMap ? 'showMap' : 'hideMap'">
        <div class="field jsmb-switch-map is-hidden-desktop">
          <b-switch v-model="showMap">Afficher la carte</b-switch>
        </div>
        <gmap-map
          :center="center"
          :zoom="gMapZoom"
          style="width:100%; height:100%;"
          ref="gmap"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
            minZoom: gMapMinZoom,
            maxZoom: gMapMaxZoom,
            gestureHandling: gestureHandling
          }"
          @bounds_changed="getBoundsMap"
          @tilesloaded="tilesLoaded"
          @dragend="dragEnd"
          @zoom_changed="zoomChanged"
          @idle="idle">
          <gmap-info-window :position="infoWindowPos" :options="{styles: mapCard.myStyles}" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            <div class="annonce-container" style="max-width: 250px; max-height: 350px;">
              <figure class="image">
                <router-link :to="{name: 'Detail', params: {id:mapCard.space_id, city:mapCard.city.toLowerCase()}}" tag="a" push>
									<img :src="mapCard.picture">
								</router-link>
                <figcaption class="is-uppercase is-size-6" v-if="mapCard.is_professional">Professionnel</figcaption>
              </figure>

              <div class="annonce-content">
                <div class="columns is-multiline is-0">
                  <div class="column is-12">
                    <p class="jsmb-favorite-type is-size-6 is-uppercase has-text-grey has-text-weight-bold">{{mapCard.space_type}} / EMPLACEMENT {{mapCard.location_type}}</p>
                    <router-link :to="{name: 'Detail', params: {id:mapCard.space_id, city:mapCard.city.toLowerCase()}}" tag="a" push>
                      <h4 class="jsmb-favorite-type-area is-size-5 has-text-primary has-text-weight-bold">
                        {{mapCard.announcement_title}} - {{mapCard.space_title}}
                      </h4>
                    </router-link>
                    <small class="jsmb-favorite-adress">{{mapCard.city}} - {{mapCard.zip_code}}</small>
                  </div>
                  <div class="column has-text-right">
                    <h4 class="jsmb-size has-text-weight-bold is-size-5">{{mapCard.surface}} m²</h4>
                  </div>
                </div>

                <div class="columns is-0 is-multiline is-vcentered jsmb-favorite-prix">
                  <div class="column is-6-desktop">
                    <p v-if="isPro"><strong class="has-text-weight-bold">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mapCard.price_ht)}}</strong> HT / jour</p>
                    <p><strong class="has-text-weight-bold">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mapCard.price_ttc)}}</strong> TTC / jour</p>
                  </div>
                </div>
              </div>
            </div>
          </gmap-info-window>
          <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :icon="m.icon" :label="m.label" :clickable="true" @click="toggleInfoWindow(m, index)"/>
        </gmap-map>
      </div>
    </div>
  </div>
</template>

<script>
import Area from '@/components/landing/landing-sections/research-cta/res-sections/Area.vue'
import Time from '@/components/landing/landing-sections/research-cta/res-sections/Time.vue'
import FavoriteButton from '@/components/template/ui/FavoriteButton.vue'
import { GET_ACCOUNT_TYPE, GET_LOCATION_TYPE, GET_OPTIONS, GET_SECURITIES, GET_SPACE_TYPE, GET_ANNOUNCEMENT } from '@/store/modules/database/actions/database.actions'
import { mapState } from 'vuex'

export default {
  name: "Research",
  props: {
    city: { type: String, default: null},

    boatTypeId: { type: String, default: '[]' },
    length: { default: 1 },
    height: { default: 1 },
    width: { default: 1 },

    beginningDate: { type: String, default: null },
    endingDate: { type: String, default: null },

    locationTypesId: { type: String, default: null },
    spaceTypesId: { type: String, default: null },
    securitiesId: { type: String, default: null },
    optionsId: { type: String, default: null },
    accountTypesId: { type: String, default: null },
  },
  components: {
    Area,
    Time,
    FavoriteButton
  },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      isDropDownActive: false,
      isPro: JSON.parse(localStorage.getItem('is-professional')) || false,
      googleMapsLoaded: false,
      stopAnnouncementLoaded: false,
      reloadAnnouncement: false,
      gestureHandling: 'auto',

      total: 0,
      current: 1,
      perPage: 6,
      order: "is-right",
      size: "",
      isRounded: false,

      showMap: false,

      // checkboxGroupLocation: [],
      // checkboxGroupTypeBS: [],

      boatData: {
        boatTypeId: [],
        length: 1,
        height: 1,
        width: 1,
      },
      beginDate: null,
      endDate: null,

      accountTypes: [],
      accountTypesCheck: [1,2],

      options: [],
      optionsCheck: [],

      securities: [],
      securitiesCheck: [],

      spaceTypes: [],
      spaceTypesCheck: [],

      locationTypes: [],
      locationTypesCheck: [],

      center: { lat: 43.2961743, lng: 5.3699525 },
      boundsMap: {
        ne: { lat: 43.477026070123515, lng: 5.494836151733352 },
        sw: { lat: 43.114783054905125, lng: 5.2450688482665555 }
      },
      gMapZoom: 11,
      gMapMinZoom: 9,
      gMapMaxZoom: 15,
      isLoadingSpaces: false,
      spaces: [],
      markers: [],
      noSpaces: false,
      geolocation: false,
      geolocCity: '',

      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      mapCard: {
        space_id: null,
        city: '',
        picture: null,
        is_professional: null,
        space_type: null,
        location_type: null,
        announcement_title: '',
        space_title: '',
        zip_code: '',
        surface: null,
        price_ht: 0.00,
        price_ttc: 0.00,
      }
    };
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Research' });

    let title = (siteName+' - Rechercher d\'annonce - '+ (this.city?this.city.charAt(0).toUpperCase() + this.city.slice(1):'')+(this.geolocCity?this.geolocCity:'')),
      description = (siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau. Trouvez l\'annonce qui correspond le mieux à vos besoins de stockage bateau, hivernage bateau, stockage bateau hors d\'eau.');

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
            'property': 'og:image',
            'content': (baseUrl+require('./../../assets/og-image.png')),
            'vmid': 'og:image'
        },
        {
            'property': 'og:image:secure_url',
            'content': (baseUrl+require('./../../assets/og-image.png')),
            'vmid': 'og:image:secure_url'
        },
        {
            'property': 'og:image:alt',
            'content': title,
            'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
  created() {
    // Get AccountTypes
    this.$store.dispatch(
      GET_ACCOUNT_TYPE
    ).then(
      resp => {
        this.accountTypes = resp
      }
    )
    // Get LocationTypes
    this.$store.dispatch(
      GET_LOCATION_TYPE
    ).then(
      resp => {
        this.locationTypes = resp
      }
    )
    // Get Options
    this.$store.dispatch(
      GET_OPTIONS
    ).then(
      resp => {
        this.options = resp
      }
    )
    // Get Securities
    this.$store.dispatch(
      GET_SECURITIES
    ).then(
      resp => {
        this.securities = resp
      }
    )
    // Get SpaceTypes
    this.$store.dispatch(
      GET_SPACE_TYPE
    ).then(
      resp => {
        this.spaceTypes = resp
      }
    )

    if (this.$props.length > 1 || this.$props.height > 1 || this.$props.width > 1) {
      this.boatData = {
        boatTypeId: JSON.parse(this.$props.boatTypeId),
        length: this.$props.length,
        height: this.$props.height,
        width: this.$props.width,
      }
    }

    if (this.$props.beginningDate !== null && this.$props.endingDate !== null) {
      this.beginDate = this.$props.beginningDate
      this.endDate = this.$props.endingDate
    }

    if (this.$props.locationTypesId !== null) {
      this.locationTypesCheck = JSON.parse(this.$props.locationTypesId)
    }

    if (this.$props.spaceTypesId !== null) {
      this.spaceTypesCheck = JSON.parse(this.$props.spaceTypesId)
    }

    if (this.$props.securitiesId !== null) {
      this.securitiesCheck = JSON.parse(this.$props.securitiesId)
    }

    if (this.$props.optionsId !== null) {
      this.optionsCheck = JSON.parse(this.$props.optionsId)
    }

    if (this.$props.accountTypesId !== null) {
      this.accountTypesCheck = JSON.parse(this.$props.accountTypesId)
    }

    if (!window.matchMedia("(min-width: 1087px)").matches) {
      this.gestureHandling = 'none'
    }else{
      this.gestureHandling = 'auto'
    }

    window.vueGoogleMapsInit(google)
    if (this.$store.state.localisation.localisation !== '') {
      var localisation = this.$store.state.localisation.localisation
      this.center = { lat: localisation.latitude, lng: localisation.longitude }
    }else if (this.city !== null) {
      this.getGeocode(this.city)
    }else{
      this.geolocate()
    }
  },
  methods: {
    changeDropDownState (space) {
      space.is_share = !space.is_share
      setTimeout(() => {
        space.is_share = !space.is_share
      }, 5000);
    },
    getUrlAnnouncement (name, params) {
      let url = this.$router.resolve({
        name: name,
        params: params,
      });
      return this.$store.state.globalConstant.BASE_URL+url.href
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.geolocation = true
      });
    },
    getGeocode (city) {
      var geocoder = new google.maps.Geocoder()
      geocoder.geocode( { "address": city }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
          this.center = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
        }
      })
    },
    getBoundsMap (bounds) {
      if (bounds !== undefined) {
        var ne = bounds.getNorthEast()
        var sw = bounds.getSouthWest()
        this.boundsMap.ne = { lat: ne.lat(), lng: ne.lng() }
        this.boundsMap.sw = { lat: sw.lat(), lng: sw.lng() }
      }
    },
    tilesLoaded () {
      if (!this.stopAnnouncementLoaded) {
        this.googleMapsLoaded = true
        this.getAnnouncement()
        this.stopAnnouncementLoaded = true
      }
    },
    dragEnd () {
      if (!this.stopAnnouncementLoaded) {
        this.googleMapsLoaded = true
        this.getAnnouncement()
        this.stopAnnouncementLoaded = true
      }
    },
    zoomChanged (zoom) {
      setTimeout(() => {
        if (!this.stopAnnouncementLoaded) {
          // this.gMapZoom = zoom
          this.googleMapsLoaded = true
          this.getAnnouncement()
          this.stopAnnouncementLoaded = true
        }
      }, 1500);
    },
    idle () {
      this.stopAnnouncementLoaded = false
    },
    addMarker (lat, lng, space) {
      this.markers.push(
        {
          icon: {
            path: "M45.79,3H4.21A2.21,2.21,0,0,0,2,5.21V21.79A2.21,2.21,0,0,0,4.21,24H20.76L25,28.24,29.24,24H45.79A2.21,2.21,0,0,0,48,21.79V5.21A2.21,2.21,0,0,0,45.79,3Z",
            fillColor: "#ffffff",
            fillOpacity: 1,
            scale: 1.5,
            strokeColor: "#bcbcbc",
            strokeWeight: 1,
            labelOrigin: {x: 26, y: 14}
          },
          label: {
            text: new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(space.price_ttc),
            color: '#000000',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          position: {
            lat: Number(lat),
            lng: Number(lng)
          },
          space: space
        }
      )
    },
    updateArea (query) {
      this.boatData = {
        boatTypeId: JSON.parse(query.boatTypeId),
        length: query.length,
        height: query.height,
        width: query.width,
      }
      this.getAnnouncement()
    },
    updateTime (query) {
      this.beginDate = query.beginningDate
      this.endDate = query.endingDate
      this.getAnnouncement()
    },
    announcementPage (value) {
      this.getAnnouncement(value)
      setTimeout(() => {
        document.querySelector('#rmvovf').scrollIntoView({ behavior: "smooth" });
      }, 1000);
    },
    getAnnouncement (current = null) {
      if (current === null) {
        current = this.current
      }
      let boatData = null
      if (this.boatData.length > 1 || this.boatData.height > 1 || this.boatData.width > 1) {
        boatData = this.boatData
      }
      this.isLoadingSpaces = true
      this.noSpaces = false
      this.spaces = []
      this.markers = []
      // Get Announcement
      this.$store.dispatch(
        GET_ANNOUNCEMENT,
        {
          perPage: this.perPage, page: current, boundsMap: this.boundsMap,
          boatData: boatData, beginDate: this.beginDate, endDate: this.endDate,
          locationTypes: this.locationTypesCheck, spaceTypes: this.spaceTypesCheck,
          securities: this.securitiesCheck, options: this.optionsCheck,
          accountTypes: this.accountTypesCheck
        }
      ).then(
        resp => {
          this.isLoadingSpaces = false
          this.spaces = resp['items']
          this.total = resp['total']
          let lat = 0,
              lng = 0,
              mover = 0
          resp['markers'].forEach((space, index) => {
            this.addMarker(space.lat, space.lng, space)
          });
          localStorage.removeItem('search-boat-data')
          if (this.boatData.length > 1 || this.boatData.height > 1 || this.boatData.width > 1) {
            localStorage.setItem('search-boat-data', JSON.stringify(boatData))
          }
          localStorage.removeItem('search-time-data')
          if (this.beginDate !== null && this.endDate !== null) {
            localStorage.setItem('search-time-data', JSON.stringify({begin: this.beginDate, end: this.endDate}))
          }
        }
      ).catch(
        () => {
          if (this.gMapZoom !== this.gMapMinZoom) {
            // this.gMapZoom = this.gMapMinZoom
          }
          this.isLoadingSpaces = false
          this.noSpaces = true
          localStorage.removeItem('search-boat-data')
          localStorage.removeItem('search-time-data')
        }
      )
    },
    locationTypesCheckbox () {
      if (this.locationTypesCheck.length !== 0) {
        let query = { ...this.$route.query, ...{ locationTypeId: JSON.stringify(this.locationTypesCheck) } }
        this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
      }else{
        if (Object.entries(this.$route.query).length > 1) {
          delete this.$route.query.locationTypeId
          let url = this.$router.resolve({ name: this.$route.name, params: this.$route.params, query: this.$route.query })
          window.location.href = url.href
        }else{
          this.$router.push({ name: this.$route.name, params: this.$route.params })
        }
      }
      this.$children.forEach(children => {
        if (children.isActive) {
          children.isActive = false
        }
      });
      this.reloadAnnouncement = true
    },
    spaceTypesCheckbox () {
      if (this.spaceTypesCheck.length !== 0) {
        let query = { ...this.$route.query, ...{ spaceTypeId: JSON.stringify(this.spaceTypesCheck) } }
        this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
      }else{
        if (Object.entries(this.$route.query).length > 1) {
          delete this.$route.query.spaceTypeId
          let url = this.$router.resolve({ name: this.$route.name, params: this.$route.params, query: this.$route.query })
          window.location.href = url.href
        }else{
          this.$router.push({ name: this.$route.name, params: this.$route.params })
        }
      }
      this.$children.forEach(children => {
        if (children.isActive) {
          children.isActive = false
        }
      });
      this.reloadAnnouncement = true
    },
    securitiesOptionsCheckbox () {
      if (this.securitiesCheck.length !== 0 || this.optionsCheck.length !== 0) {
        let paramsQuery = {}
        let removeParam = false
        if (this.securitiesCheck.length !== 0) {
          paramsQuery = { ...paramsQuery, ...{ securityId: JSON.stringify(this.securitiesCheck) } }
        }else{
          if (this.$route.query.securityId !== undefined) {
            delete this.$route.query.securityId
            removeParam = true
          }
        }
        if (this.optionsCheck.length !== 0) {
          paramsQuery = { ...paramsQuery, ...{ optionId: JSON.stringify(this.optionsCheck) } }
        }else{
          if (this.$route.query.optionId !== undefined) {
            delete this.$route.query.optionId
            removeParam = true
          }
        }
        if (!removeParam) {
          let query = { ...this.$route.query, ...paramsQuery }
          this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
        }else{
          if (Object.entries(this.$route.query).length >= 1) {
            let url = this.$router.resolve({ name: this.$route.name, params: this.$route.params, query: this.$route.query })
            window.location.href = url.href
          }else{
            this.$router.push({ name: this.$route.name, params: this.$route.params })
          }
        }
      }else{
        if (Object.entries(this.$route.query).length > 1) {
          delete this.$route.query.securityId
          delete this.$route.query.optionId
          let url = this.$router.resolve({ name: this.$route.name, params: this.$route.params, query: this.$route.query })
          window.location.href = url.href
        }else{
          this.$router.push({ name: this.$route.name, params: this.$route.params })
        }
      }
      this.$children.forEach(children => {
        if (children.isActive) {
          children.isActive = false
        }
      });
      this.reloadAnnouncement = true
    },
    accountTypesCheckbox () {
      if (this.accountTypesCheck.length !== 0) {
        let query = { ...this.$route.query, ...{ accountTypeId: JSON.stringify(this.accountTypesCheck) } }
        this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
      }else{
        if (Object.entries(this.$route.query).length > 1) {
          delete this.$route.query.accountTypeId
          let url = this.$router.resolve({ name: this.$route.name, params: this.$route.params, query: this.$route.query })
          window.location.href = url.href
        }else{
          this.$router.push({ name: this.$route.name, params: this.$route.params })
        }
      }
      this.$children.forEach(children => {
        if (children.isActive) {
          children.isActive = false
        }
      });
      this.reloadAnnouncement = true
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.mapCard = {
        space_id: marker.space.space_id,
        city: marker.space.city,
        picture: marker.space.picture,
        is_professional: marker.space.is_professional,
        space_type: marker.space.space_type,
        location_type: marker.space.location_type,
        announcement_title: marker.space.announcement_title,
        space_title: marker.space.space_title,
        zip_code: marker.space.zip_code,
        surface: marker.space.surface,
        price_ht: marker.space.price_ht,
        price_ttc: marker.space.price_ttc,
      }
      if (this.currentMidx == idx) {
        // check if its the same marker that was selected if yes toggle
        this.infoWinOpen = !this.infoWinOpen;
      }else{
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    }
  },
  watch: {
    googleMapsLoaded () {
      this.$nextTick(() => {
        this.$refs.gmap.$mapPromise.then(map => {
          map.setOptions({
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP
            },
          });
        });
      })
    },
    reloadAnnouncement () {
      if (this.reloadAnnouncement) {
        this.reloadAnnouncement = false
        this.getAnnouncement()
      }
    },
    geolocation () {
      var geocoder = new google.maps.Geocoder
      geocoder.geocode({'location': this.center}, (results, status) => {
        if (status === 'OK' && results[0]) {
          this.geolocCity = results[0]['address_components'][2]['long_name']+' - '+results[0]['address_components'][6]['long_name']
        }
      });
    },
    showMap (value) {
      if (value) {
        this.gestureHandling = 'greedy'
      }else{
        this.gestureHandling = 'none'
      }
    }
  },
  computed: mapState({
    localisation: 'localisation',
  })
};
</script>

<style scoped lang="css">
  .button.myPrimary:hover {
    background-color: #27c6bd;
    border-color: transparent;
    color: #fff;
  }
  .research-container,
  .annonce-research-container{
    padding:1rem 2rem;
  }
  .annonce-search-input {
    padding:1rem 2rem 0;
  }
  .annonce-research-container .dropdown + .dropdown,
  .annonce-research-container-mobile .dropdown + .dropdown {
    margin: 0;
  }
  .annonce-research-container .buttons,
  .annonce-research-container-mobile .buttons {
    justify-content: space-between;
  }
  .annonce-research-container .b-checkbox.checkbox {
    align-items: flex-start;
  }
  .annonce-research-container a.dropdown-item:hover {
    background: #ffffff
  }
  a.dropdown-item {
    padding: 1rem
  }
  .annonce-main {
    margin-bottom: 1rem;
  }
  .annonce-main figure {
    position: relative;
  }
  .annonce-main figcaption {
    position: absolute;
    z-index: 20;
    top:0;
    right:0;
    background:#df5051;
    color:#fff;
    padding: 0.5rem 1rem
  }
  .annonce-container {
    border: 1px solid #c2c2c2
  }
  .annonce-content {
    padding:1rem 0.5rem 0;
  }
  .jsmb-favorite-prix {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .annonce-social {
    position: relative;
  }
  .jsmb-share {
    position:relative;
    right: 40px;
    bottom:0px;
  }
  .jsmb-share .dropdown-trigger {
    z-index: 20;
  }
  .dropdown.is-up .dropdown-menu {
    bottom: -7px;
    padding-bottom: 4px;
    padding-top: initial;
    top: auto;
    left: -110px;
    z-index: 555;
  }
  .jsmb-inline {
    display: inline;
    margin-bottom: 0rem;
  }
  .jsmb-inline:last-of-type {
    margin-bottom: 0;
  }
  .jsmb-share .button {
    background: transparent;
    border: none;
  }
  .jsmb-share .button:hover {
    color:#27c6bd
  }
  .jsmb-share .dropdown-content {
    background: transparent;
    box-shadow: none;
    width: fit-content;
  }
  @media screen and (max-width: 768px){
    .annonce-options {
      width: 100%;
    }
  }
  @media screen and (max-width: 1087px){
    #gmap {
      /*position: relative !important;*/
      width: 100%;
      height: 100%;
      order: 1
    }
    .mapIsActive {
      height: 100%;
    }
    .mapIsPassive {
      height: 50%;
    }
    .jsmb-listing-research {
      order: 2
    }
    
    #rmvovf {
      overflow-x: hidden;
      display: flex;
      flex-direction: column
    }
    .showMap {
      display: block;
      opacity: 1 !important;
      z-index: 1 !important;
      
    }
    .hideMap {
      position:absolute !important;
      opacity: 0 !important;
      z-index: -200 !important;
      order: 3
    }
  }
  @media screen and (min-width: 510px) and (max-width: 768px){
    .column.is-half, .column.is-half-tablet {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: 50%;
    }
  }
  .dropdown.is-hoverable:hover .dropdown-menu {
    left: -117%;
    right: -60%;
  }
  .espace-annonce {
    margin-bottom: 1rem;
    display: block;
  }
  a.detailLink:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  .vue-map-container {
    position: sticky;
    top:0;
    height: 100vh !important;
  }
  .refresh-search-button {
    font-size:0.7rem;
    color: #df5051;
    padding-right: 0.8rem;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .columns.is-variable .column {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
  }
</style>
<style>
  @media screen and (min-width: 450px) and (max-width: 2560px) {
    .is-bottom-center .dropdown-menu {
      transform: translateX(-40%) !important;
      left: 40% !important;
    }
  }
  @media screen and (max-width: 449px) {
   .is-bottom-center .dropdown-menu {
    transform: inherit;
    left: inherit;
  } 
  }
  .annonce-research-container .dropdown-menu {
    z-index: 777;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2);
  }
  .annonce-research-container .dropdown .background {
    z-index: 40;
  }
  .annonce-research-container .annonce-option-dropdown .dropdown-menu {
    width: max-content;
  }
  @media screen and (max-width:749px){
    .annonce-research-container .annonce-surface .dropdown-menu  {
      width: max-content;
    }
  }
  .annonce-research-container-mobile {
    padding: 1rem 2rem;
  }
  .annonce-research-container-mobile .buttons {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .annonce-option-date .columns.is-mobile > .column.is-6 {
    width: 100%;
  }
  .annonce-research-container .dropdown-content {
    border-radius:4px;
    box-shadow:none
  }
  .jsmb-switch-map {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 666;
    background: rgba(255, 255, 255, 0.7);
    padding: 0.5rem;
    border-radius: 50px;
  }
</style>
