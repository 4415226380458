<template>
  <button class="button jsmb-love" @click="switchHeart">
    <i class="fa-heart" :class="changeStateFav ? 'fas' : 'far'"></i>
  </button>
</template>

<script>
import { ADD_BOOKMARK_SPACE, REMOVE_BOOKMARK_SPACE } from '@/store/modules/database/actions/database.actions'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'FavoriteButton',
  props: {
    spaceId: { type: Number },
    isFavorite: { type: Boolean, default: false }
  },
  data () {
    return {
      isAuth: false,
      changeStateFav: false,
    }
  },
  methods: {
    switchHeart() {
      if (this.isAuth) {
        this.changeStateFav = !this.changeStateFav;
        if (this.changeStateFav) {
          // Add BookmarkSpace
          this.$store.dispatch(
            ADD_BOOKMARK_SPACE,
            {space_id: this.$props.spaceId}
          )
        }else{
          // Remove BookmarkSpace
          this.$store.dispatch(
            REMOVE_BOOKMARK_SPACE,
            {space_id: this.$props.spaceId}
          )
        }
      }else{
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      }
    },
  },
  created () {
    this.changeStateFav = this.$props.isFavorite
    if (localStorage.getItem('user-id') !== null) {
      this.isAuth = true
    }
  }
}
</script>

<style scoped lang="css">
  .button.jsmb-love {
    background: transparent;
    border: none;
  }
  .button.jsmb-love:hover:not(.disabled) {
    color:#27c6bd
  }
  .annonce-social .button.jsmb-love {
    position:absolute;
    z-index: 20;
    right: 0px;
    bottom:0px;
  }
  .jsmb-info-annonce .button.jsmb-love {
		position:absolute;
		right: 20px;
		bottom:10px;
	}
</style>
